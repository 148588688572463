import React, { useEffect, useRef, useState } from "react";
import { eye, greenCheck, insertLink, mailIcon } from "../../../libs/images";
import {
  emailRegex,
  internationalPhoneRegex,
  passwRegex,
  phoneRegex,
} from "../../../libs/regexes";
import {
  UseFetchGet,
  UseFetchGetAnonymos,
  UseFetchPostAnonymos,
} from "../../../hooks/fetchFunctions";
import {
  getGlobalScripsData,
  writePerformanceLog,
} from "../../../libs/helpers";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { getFromLocal } from "../../../hooks/localStorage";
import { applicationType } from "../../../applicationType";
import { useLocalization } from "../../Context/LocalizationContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Register({
  handleAuthPopupWindow,
  isPowerLinkPage,
  registerData,
  registerDataRef,
  handleRegisterParams,
  validationOptions,
  setValidationOptions,
  userData,
  handleSetNotif,
  sendOTPByPhone,
  handleOtpParams,
  handleLoginStep,
}) {
  const { currentLocale, languageDirection } = useLocalization();
  const localization = React.useMemo(
    () => currentLocale.auth.register,
    [currentLocale],
  );
  const [loading, setLoading] = useState(false);

  const [emailErr, setEmailErr] = useState(false);
  const [powerlinkTokenError, setPowerlinkTokenError] = useState(false);
  const [companyPassCodeError, setСompanyPassCodeError] = useState(false);
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [companyNameErr, setCompanyNameErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [verifyPasswordErr, setVerifyPasswordErr] = useState(false);
  const [phoneNumberErr, setPhoneNumberErr] = useState(false);
  const [confRegError, setConfRegError] = useState(false);
  const [isFacebookForm, setIsFacebookForm] = useState(false);
  const [isFacebookSucceed, setIsFacebookSucceed] = useState(false); //TODO
  // const [isFacebookSucceed, setIsFacebookSucceed] = useState(true); //TODO = true for now

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordVerify, setShowPasswordVerify] = useState(false);

  const fireberryRegisterData = useRef(null);

  function toggleShowPassword(e) {
    e.stopPropagation();
    setShowPassword(!showPassword);
  }
  function toggleShowPasswordVerify() {
    setShowPasswordVerify(!showPasswordVerify);
  }

  const isPhoneNumberAvailable = async (phone) => {
    try {
      const res = await UseFetchGetAnonymos(
        "/api/auth/isPhoneNumberAvailable",
        {
          phoneNumber: phone,
        },
        null,
        false,
        "history",
      );

      return res?.data ?? false;
    } catch (error) {
      return false;
    }
  };
  const isFormValid = async () => {
    const validationErrors = [];

    if (!emailRegex.test(registerData.email)) {
      setEmailErr(true);
      validationErrors.push(false);
    }
    if (registerData.powerlinkToken === "") {
      setPowerlinkTokenError(true);
      // if (applicationType === "extension") {
      if (isPowerLinkPage) {
        validationErrors.push(false);
      }
    }
    // if (registerData.companyPassCode === "") {
    //   setСompanyPassCodeError(true);
    // }
    if (registerData.firstName === "") {
      setFirstNameErr(true);
    }
    if (registerData.lastName === "") {
      setLastNameErr(true);
    }
    if (registerData.companyName === "") {
      setCompanyNameErr(true);
    }
    /* if (login.length < 6) {
      setLoginErr(true)
    } */
    if (!passwRegex.test(registerData.password)) {
      setPasswordErr(true);
      validationErrors.push(false);
    }
    if (registerData.verifyPassword !== registerData.password) {
      //console.log('verifyPassword:', verifyPassword, 'email-', email);
      //console.log(verifyPassword !== password);
      setVerifyPasswordErr(true);
      validationErrors.push(false);
    }

    if (!internationalPhoneRegex.test(registerData.phoneNumberLogin)) {
      setPhoneNumberErr(true);
      validationErrors.push(false);
    } else {
      const phoneAvailable = await isPhoneNumberAvailable(
        registerData.phoneNumberLogin,
      );
      if (!phoneAvailable) {
        enqueueSnackbar(localization.userAlreadyExists, {
          variant: "error",
        });
        setPhoneNumberErr(true);
        validationErrors.push(false);
      }
    }

    if (!validationOptions.confirmRegulations) {
      validationErrors.push(false);
      setConfRegError(true);
    }

    return validationErrors;
  };
  const handleSubmitRegisterForm = async (e) => {
    e.preventDefault();

    setLoading(true);
    const savedUserData = getFromLocal("userData");
    const intervalValue = 60 * 1000;
    let counter = 1;
    const requestTimeout = setInterval(() => {
      writePerformanceLog("ApplicationHealthLog", {
        companyId: savedUserData?.CompanyId || "",
        userId: savedUserData?.Udid || "",
        action: "webAppApiPendingIssueFromRegister",
        date: new Date().toISOString(),
        // url: pageUrl,
        applicationType,
        pendingTime: counter,
      });

      counter++;
    }, intervalValue);

    const validationErrors = await isFormValid();

    clearInterval(requestTimeout);

    if (validationErrors.length === 0) {
      setIsFacebookForm(true);
      enqueueSnackbar(localization.loginWithFacebookNotif, {
        variant: "info",
        // autoHideDuration: 10000,
        autoHideDuration: null,
      });
    } else {
      handleSetNotif(currentLocale.auth.notifications.register.error, "error");
    }
    setLoading(false);
  };
  const handleRegisterSubmit = () => {
    let params;
    if (isPowerLinkPage) {
      params = {
        PowerLinkToken: registerData.powerlinkToken,
        Email: registerData.email,
        Password: registerData.password,
        UserGlobalId: fireberryRegisterData.current.globalID,
        PhoneNumber: registerData.phoneNumberLogin,
        FirstName: registerData.firstName,
        LastName: registerData.lastName,
        CompanyId: fireberryRegisterData.current.companyID,
        CompanyName: registerData.companyName,
        Standalone: false,
      };
    } else {
      params = {
        // PowerLinkToken: powerlinkToken,
        Email: registerData.email,
        Password: registerData.password,
        // UserGlobalId: userData.Udid,
        PhoneNumber: registerData.phoneNumberLogin,
        FirstName: registerData.firstName,
        LastName: registerData.lastName,
        // CompanyId: companyID,
        // CompanyName: companyName,
        companyPassCode: registerData.companyPassCode,
        Standalone: true,
      };
    }

    //console.log(validationErrors)
    UseFetchPostAnonymos("/api/auth/registerUser", params, "config")
      .then((res) => {
        // console.log(res, "res registerUser");
        if (res?.data?.result === false || res?.data?.success === false) {
          // alert(res.data.message)
          handleSetNotif(res.data.message, "error");
          return;
        } else {
          //?
          // handleGetGlobalUserStatus();
          handleRegisterParams({
            powerlinkToken: "",
            companyPassCode: "",
            companyName: "",
            email: "",
            password: "",
            verifyPassword: "",
            phoneNumberLogin: "",
            firstName: "",
            lastName: "",
          });
        }
        if (res.data) {
          handleAuthPopupWindow("verifyOTP");
          handleOtpParams({ phone: params.PhoneNumber });
          sendOTPByPhone(params.PhoneNumber).then(async (data) => {
            const response = await data.json();
            if (response.success == true) {
              handleSetNotif(
                currentLocale.auth.notifications.register.otpSend,
                "success",
              );
            }
          });

          const stateObject = {
            additionalInformation: "",
          };
          const newURL = "/";

          window.history.replaceState(stateObject, "", newURL);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleRegisterSubmitFacebookWithManualSuccess = (isSuccess = true) => {
    let params = {
      // PowerLinkToken: powerlinkToken,
      Email: registerData.email,
      Password: registerData.password,
      // UserGlobalId: userData.Udid,
      PhoneNumber: registerData.phoneNumberLogin,
      FirstName: registerData.firstName,
      LastName: registerData.lastName,
      // CompanyId: companyID,
      // CompanyName: companyName,
      // companyPassCode: registerData.companyPassCode,
      Standalone: true,
      signupData: isSuccess
        ? {
            data: {
              phone_number_id: "514523945073955",
              waba_id: "521502171038335",
            },
            type: "WA_EMBEDDED_SIGNUP",
            event: "FINISH",
            version: "3",
          }
        : {
            data: {
              phone_number_id: "539015089286234",
              waba_id: "474204509117595",
            },
            type: "WA_EMBEDDED_SIGNUP",
            event: "FINISH",
            version: "3",
          },
      // signupData: registerData.signupData,
      testIsSuccess: isSuccess,
    };
    setLoading(true);

    //console.log(validationErrors)
    // UseFetchPostAnonymos("/api/auth/registerUser", params, "config")
    UseFetchPostAnonymos("/api/auth/registerUserFBSignup", params, "config")
      .then((res) => {
        // console.log(res, "res registerUser");
        if (res?.data?.result === false || res?.data?.success === false) {
          // alert(res.data.message)
          handleSetNotif(res.data.message, "error");
          return;
        } else {
          //?
          // handleGetGlobalUserStatus();
          handleRegisterParams({
            powerlinkToken: "",
            companyPassCode: "",
            companyName: "",
            email: "",
            password: "",
            verifyPassword: "",
            phoneNumberLogin: "",
            firstName: "",
            lastName: "",
            signupData: null,
          });
        }
        if (res.data) {
          handleAuthPopupWindow("verifyOTP");
          handleOtpParams({ phone: params.PhoneNumber });
          sendOTPByPhone(params.PhoneNumber).then(async (data) => {
            const response = await data.json();
            if (response.success == true) {
              handleSetNotif(
                currentLocale.auth.notifications.register.otpSend,
                "success",
              );
            }
          });
          handleLoginStep(params.PhoneNumber, params.Password);
          // localStorage.setItem("whatsapp-token", res.data.token);
          handleAuthPopupWindow("");

          const stateObject = {
            additionalInformation: "",
          };
          const newURL = "/";

          window.history.replaceState(stateObject, "", newURL);
          // window.location.reload();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  const handleRegisterSubmitFacebook = React.useCallback(() => {
    let params = {
      // PowerLinkToken: powerlinkToken,
      Email: registerDataRef.current.email,
      Password: registerDataRef.current.password,
      // UserGlobalId: userData.Udid,
      PhoneNumber: registerDataRef.current.phoneNumberLogin,
      FirstName: registerDataRef.current.firstName,
      LastName: registerDataRef.current.lastName,
      // CompanyId: companyID,
      // CompanyName: companyName,
      // companyPassCode: registerData.companyPassCode,
      Standalone: true,
      // signupData: {
      //   data: {
      //     phone_number_id: "539015089286234",
      //     waba_id: "474204509117595",
      //   },
      //   type: "WA_EMBEDDED_SIGNUP",
      //   event: "FINISH",
      //   version: "3",
      // },
      signupData: registerDataRef.current.signupData,
      testIsSuccess: true,
    };
    setLoading(true);

    //console.log(validationErrors)
    // UseFetchPostAnonymos("/api/auth/registerUser", params, "config")
    UseFetchPostAnonymos("/api/auth/registerUserFBSignup", params, "config")
      .then(async (res) => {
        // console.log(res, "res registerUser");
        if (res?.data?.result === false || res?.data?.success === false) {
          // alert(res.data.message)
          handleSetNotif(res.data.message, "error");
          return;
        } else {
          //?
          // handleGetGlobalUserStatus();
          handleRegisterParams({
            powerlinkToken: "",
            companyPassCode: "",
            companyName: "",
            email: "",
            password: "",
            verifyPassword: "",
            phoneNumberLogin: "",
            firstName: "",
            lastName: "",
            signupData: null,
          });
        }
        if (res.data) {
          handleAuthPopupWindow("verifyOTP");
          handleOtpParams({ phone: params.PhoneNumber });
          const response = await sendOTPByPhone(params.PhoneNumber);
          if (response.success === true) {
            handleSetNotif(
              currentLocale.auth.notifications.register.otpSend,
              "success",
            );
          }
          // sendOTPByPhone(params.PhoneNumber).then(async (data) => {
          //   const response = await data.json();
          //   if (response.success == true) {
          //     handleSetNotif(
          //       currentLocale.auth.notifications.register.otpSend,
          //       "success",
          //     );
          //   }
          // });
          // handleLoginStep(params.PhoneNumber, params.Password);
          // handleAuthPopupWindow("");

          const stateObject = {
            additionalInformation: "",
          };
          const newURL = "/";

          window.history.replaceState(stateObject, "", newURL);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [
    currentLocale,
    handleAuthPopupWindow,
    handleOtpParams,
    handleRegisterParams,
    handleSetNotif,
    registerDataRef,
    sendOTPByPhone,
  ]);

  const handleRegulationConfirmation = (e) => {
    const checked = e.target.checked;
    setValidationOptions((prevOptions) => {
      return { ...prevOptions, confirmRegulations: checked };
    });

    if (checked) {
      setConfRegError(false);
    }
  };
  const handlePasswordValidation = (e) => {
    const value = e.target.value;

    if (value.length >= 8) {
      setValidationOptions((prevOptions) => {
        return { ...prevOptions, minimumCharacters: true };
      });
    } else {
      setValidationOptions((prevOptions) => {
        return { ...prevOptions, minimumCharacters: false };
      });
    }

    handleRegisterParams({ password: value });

    if (passwRegex.test(value)) {
      setPasswordErr(false);
    }
  };
  const handleVerifyPasswordValidation = (e) => {
    const value = e.target.value;
    handleRegisterParams({ verifyPassword: value });
    //console.log('e.target.value', value, 'password', password);

    if (
      // validationOptions.lowerCaseLetter &&
      // validationOptions.number &&
      // validationOptions.specialSign &&
      validationOptions.minimumCharacters
      // validationOptions.upperCaseLetter
    ) {
      if (value === registerData.password) {
        setVerifyPasswordErr(false);
        setValidationOptions((prevOptions) => {
          return { ...prevOptions, samePassword: true };
        });
      } else {
        setValidationOptions((prevOptions) => {
          return { ...prevOptions, samePassword: false };
        });
      }
    } else {
      setValidationOptions((prevOptions) => {
        return { ...prevOptions, samePassword: false };
      });
    }
  };

  async function handleFireberryRegisterData() {
    fireberryRegisterData.current = {
      ...(await getGlobalScripsData()),
    };
  }

  useEffect(() => {
    if (isPowerLinkPage) handleFireberryRegisterData();
  }, [isPowerLinkPage]);

  //! Facebook form
  // Response callback
  const fbLoginCallback = (response) => {
    if (response.authResponse) {
      const code = response.authResponse.code;
      console.log("response: ", code);
      logFBSignupEvent({ data: code, type: "callback" });
    } else {
      console.log("response: ", response);
      logFBSignupEvent({ data: response, type: "callback catch" });
    }
  };
  const logFBSignupEvent = React.useCallback((data) => {
    fetch("https://prioritysdk.funner.co.il/api/whatsapp/logFBSignupEvent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  // Launch method and callback registration
  const launchWhatsAppSignup = () => {
    if (!window.FB) {
      console.error("Facebook SDK not loaded yet.");
      return;
    }
    logFBSignupEvent({ data: { code: "test" }, type: "test" });
    window.FB.login(fbLoginCallback, {
      //TODO
      config_id: "453418974468749",
      response_type: "code",
      override_default_response_type: true,
      extras: {
        setup: {},
        featureType: "",
        sessionInfoVersion: "3",
      },
    });
  };

  useEffect(() => {
    if (document.getElementById("funner-facebook-sdk")) return;

    // Facebook script element
    const facebookScript = document.createElement("script");
    facebookScript.id = "funner-facebook-sdk";
    facebookScript.async = true;
    facebookScript.defer = true;
    facebookScript.crossOrigin = "anonymous";
    facebookScript.src = "https://connect.facebook.net/en_US/sdk.js";
    document.body.appendChild(facebookScript);

    // SDK initialization
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "3977121505844289",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v19.0",
      });
    };

    return () => {
      const script = document.getElementById("funner-facebook-sdk");
      if (script) {
        script.parentNode.removeChild(script);
      }
      delete window.fbAsyncInit;
    };
  }, []);

  const onFacebookMessage = React.useCallback(
    (event) => {
      console.log("message event: ", event);
      if (
        event.origin !== "https://www.facebook.com" &&
        event.origin !== "https://web.facebook.com"
      )
        return;
      try {
        const data = JSON.parse(event.data);

        if (data.type === "WA_EMBEDDED_SIGNUP") {
          // console.log("message event: ", data);
          handleRegisterParams({
            signupData: data,
          });

          logFBSignupEvent({ data: data, type: "message" });
          if (data.event === "FINISH") {
            setIsFacebookSucceed(true);

            setTimeout(() => {
              handleRegisterSubmitFacebook();
            }, 0);
          } else {
            enqueueSnackbar(localization.errorFacebook, {
              variant: "error",
            });
          }
        }
      } catch (error) {
        console.log("message error: ", error);
        console.log("message event: ", event.data);
        logFBSignupEvent({ data: {}, type: "message catch" });
      }
    },
    [
      handleRegisterParams,
      handleRegisterSubmitFacebook,
      localization.errorFacebook,
      logFBSignupEvent,
    ],
  );

  useEffect(() => {
    if (isFacebookForm) {
      window.addEventListener("message", onFacebookMessage);
    }

    return () => {
      window.removeEventListener("message", onFacebookMessage);
    };
  }, [isFacebookForm, onFacebookMessage]);

  const inputWrapperStyles = {
    display: "block",
    flex: "0 0 calc(50% - 5px)",
    position: "relative",
  };

  return (
    <div
      className="get-token-popup__window get-token-popup__window_3"
      style={{ position: "relative" }}
    >
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "rgba(255, 255, 255, 0.5)",
            zIndex: 1000000000000,
          }}
        >
          <CircularProgress color="secondary" size={40} />
        </Box>
      )}
      {isFacebookForm ? (
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={1.5} justifyContent={"center"}>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() => launchWhatsAppSignup()}
                    disabled={isFacebookSucceed}
                  >
                    {localization.loginWithFacebook}
                  </Button>
                </Grid>
                {/* <Grid item>
                  <Button
                    variant="contained"
                    onClick={() =>
                      handleRegisterSubmitFacebookWithManualSuccess(true)
                    }
                  >
                    Register with Facebook success
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() =>
                      handleRegisterSubmitFacebookWithManualSuccess(false)
                    }
                  >
                    Register with Facebook error
                  </Button>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <form
          // onSubmit={handleRegisterSubmit}
          onSubmit={handleSubmitRegisterForm}
          className="get-token-popup__form form-get-token-popup"
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              marginBottom: "10px",
            }}
          >
            {isPowerLinkPage && (
              <Box sx={inputWrapperStyles}>
                <TextField
                  label={localization.powerlinkTokenTitle}
                  variant="outlined"
                  fullWidth
                  size="small"
                  InputProps={{
                    sx: {
                      fontSize: 14,
                      "& input": {
                        py: 1.5,
                        textAlign:
                          languageDirection === "rtl" ? "right" : "left",
                      },
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={registerData.powerlinkToken}
                  onChange={(e) => {
                    const value = e.target.value;
                    handleRegisterParams({ powerlinkToken: value });
                    if (value.length > 0 && powerlinkTokenError) {
                      setPowerlinkTokenError(false);
                    }
                  }}
                  error={powerlinkTokenError}
                />
              </Box>
            )}

            {isPowerLinkPage && (
              <Box sx={inputWrapperStyles}>
                <TextField
                  label={localization.companyNameTitle}
                  variant="outlined"
                  fullWidth
                  size="small"
                  InputProps={{
                    sx: {
                      fontSize: 14,
                      "& input": {
                        py: 1.5,
                        textAlign:
                          languageDirection === "rtl" ? "right" : "left",
                      },
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={registerData.companyName}
                  onChange={(e) => {
                    const value = e.target.value;
                    handleRegisterParams({ companyName: value });
                    if (value.length > 0 && companyNameErr) {
                      setCompanyNameErr(false);
                    }
                  }}
                  error={companyNameErr}
                />
              </Box>
            )}

            <Box sx={inputWrapperStyles}>
              <TextField
                label={localization.firstNameTitle}
                variant="outlined"
                fullWidth
                size="small"
                InputProps={{
                  sx: {
                    fontSize: 14,
                    "& input": {
                      py: 1.5,
                      textAlign: languageDirection === "rtl" ? "right" : "left",
                    },
                  },
                }}
                value={registerData.firstName}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  const value = e.target.value;
                  handleRegisterParams({ firstName: value });
                  if (value.length > 0 && firstNameErr) {
                    setFirstNameErr(false);
                  }
                }}
                error={firstNameErr}
              />
            </Box>

            <Box sx={inputWrapperStyles}>
              <TextField
                label={localization.lastNameTitle}
                variant="outlined"
                fullWidth
                size="small"
                InputProps={{
                  sx: {
                    fontSize: 14,
                    "& input": {
                      py: 1.5,
                      textAlign: languageDirection === "rtl" ? "right" : "left",
                    },
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={registerData.lastName}
                onChange={(e) => {
                  const value = e.target.value;
                  handleRegisterParams({ lastName: value });
                  if (value.length > 0 && lastNameErr) {
                    setLastNameErr(false);
                  }
                }}
                error={lastNameErr}
              />
            </Box>

            <Box sx={inputWrapperStyles}>
              <TextField
                label={localization.emailTitle}
                variant="outlined"
                fullWidth
                size="small"
                InputProps={{
                  sx: {
                    fontSize: 14,
                    "& input": {
                      py: 1.5,
                      textAlign: languageDirection === "rtl" ? "right" : "left",
                    },
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={registerData.email}
                onChange={(e) => {
                  const value = e.target.value;
                  handleRegisterParams({ email: value });
                  if (emailRegex.test(value) && emailErr) {
                    setEmailErr(false);
                  }
                }}
                error={emailErr}
              />
            </Box>

            <Box sx={inputWrapperStyles}>
              <TextField
                label={localization.phoneTitle}
                variant="outlined"
                fullWidth
                size="small"
                InputProps={{
                  sx: {
                    fontSize: 14,
                    "& input": {
                      py: 1.5,
                      textAlign: languageDirection === "rtl" ? "right" : "left",
                    },
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={registerData.phoneNumberLogin}
                onChange={(e) => {
                  const value = e.target.value;
                  handleRegisterParams({
                    phoneNumberLogin: value.replace(/\D/g, ""),
                  });
                  if (internationalPhoneRegex.test(value) && phoneNumberErr) {
                    setPhoneNumberErr(false);
                  }
                }}
                error={phoneNumberErr}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              paddingTop: "20px",
              borderTop: "1px solid rgba(0, 0, 0, 0.12)",
              marginTop: "20px",
              mb: 1,
            }}
          >
            <Box sx={inputWrapperStyles}>
              <TextField
                label={localization.passwordTitle}
                variant="outlined"
                fullWidth
                size="small"
                type={showPassword ? "text" : "password"}
                value={registerData.password}
                onChange={(e) => handlePasswordValidation(e)}
                error={passwordErr}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  sx: {
                    fontSize: 14,
                    "& input": {
                      py: 1.5,
                      textAlign: languageDirection === "rtl" ? "right" : "left",
                    },
                  },
                  startAdornment:
                    languageDirection === "rtl" ? (
                      <InputAdornment
                        position={languageDirection === "rtl" ? "start" : "end"}
                      >
                        <IconButton size="small" onClick={toggleShowPassword}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  endAdornment:
                    languageDirection === "ltr" ? (
                      <InputAdornment
                        position={languageDirection === "rtl" ? "start" : "end"}
                      >
                        <IconButton size="small" onClick={toggleShowPassword}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                }}
              />
            </Box>

            <Box sx={inputWrapperStyles}>
              <TextField
                label={localization.passwordVerifyTitle}
                variant="outlined"
                fullWidth
                size="small"
                type={showPasswordVerify ? "text" : "password"}
                value={registerData.verifyPassword}
                onChange={(e) => handleVerifyPasswordValidation(e)}
                error={verifyPasswordErr}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  sx: {
                    fontSize: 14,
                    "& input": {
                      py: 1.5,
                      textAlign: languageDirection === "rtl" ? "right" : "left",
                    },
                  },
                  startAdornment:
                    languageDirection === "rtl" ? (
                      <InputAdornment
                        position={languageDirection === "rtl" ? "start" : "end"}
                      >
                        <IconButton
                          size="small"
                          onClick={toggleShowPasswordVerify}
                        >
                          {showPasswordVerify ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  endAdornment:
                    languageDirection === "ltr" ? (
                      <InputAdornment
                        position={languageDirection === "rtl" ? "start" : "end"}
                      >
                        <IconButton
                          size="small"
                          onClick={toggleShowPasswordVerify}
                        >
                          {showPasswordVerify ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                }}
              />
            </Box>
          </Box>

          <div className="form-get-token-popup__block">
            <div className="form-get-token-popup__check-list">
              <div
                className={`form-get-token-popup__check-list-item  ${
                  validationOptions.minimumCharacters ? "valid" : ""
                }`}
              >
                <img src={greenCheck} className="valid-icon" alt="" />
                {localization.passwordValidation.minChars}
              </div>
              <div
                className={`form-get-token-popup__check-list-item  ${
                  validationOptions.samePassword ? "valid" : ""
                }`}
              >
                <img src={greenCheck} className="valid-icon" alt="" />
                {localization.passwordValidation.verifyPassEqual}
              </div>
            </div>
          </div>

          <div
            className="form-get-token-popup__block form-get-token-popup__block_border-top"
            style={{
              justifyContent: "center",
            }}
          >
            <div className="form-get-token-popup__gray-check">
              <input
                onChange={handleRegulationConfirmation}
                name="confirm-policy"
                type="checkbox"
                checked={validationOptions.confirmRegulations}
                className="confirm-policy-checkbox"
              />
              <label
                htmlFor="confirm-policy"
                className={`form-get-token-popup__checkbox confirm-policy ${
                  confRegError ? "error" : ""
                }`}
              >
                <img src={greenCheck} className="valid-icon" alt="" />
              </label>
              <a href="">{localization.confirmRegulationsLabel}</a>
            </div>

            <div className="form-get-token-popup__buttons-col">
              <button
                type="submit"
                className="get-token-popup__main-btn popup__blue-btn h42"
              >
                {localization.submitBtn}
              </button>
            </div>
          </div>
        </form>
      )}

      <div className="form-get-token-popup__button-wrap form-get-token-popup__button-wrap--register">
        <div className="btns-wrapper">
          <div className="text" onClick={() => handleAuthPopupWindow("login")}>
            {localization.loginLinkText}
          </div>
          {isFacebookForm && (
            <div className="text" onClick={() => setIsFacebookForm(false)}>
              {localization.returnRegister}
            </div>
          )}
        </div>
      </div>

      <div className="get-token-popup__bottom-links">
        <a
          href="#"
          className="get-token-popup__bottom-link get-token-popup__icon-link"
        >
          <img src={mailIcon} className="icon" alt="" />
          {localization.footer.email1}
        </a>
        <a
          href={`mailto:${localization.footer.email2}`}
          className="get-token-popup__bottom-link get-token-popup__icon-link"
        >
          <img src={mailIcon} className="icon" alt="" />
          {localization.footer.email2}
        </a>
        <a
          href={`https://${localization.footer.site}`}
          className="get-token-popup__bottom-link get-token-popup__icon-link"
        >
          <img src={insertLink} className="icon" alt="" />
          {localization.footer.site}
        </a>
      </div>
    </div>
  );
}

export default Register;
